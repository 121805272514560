import React from "react";
// import { connect } from 'react-redux';
import "../../../assets/js/scripts.js";
import "../../../assets/css/styles.css";
import { Link } from "react-router-dom";

//  class Header extends Component {

//   toggleMenu(){
//     const menu = document.getElementsByClassName('menu-toggle');

//       console.log('menu clicked', menu);

//   }
// //   $('.menu-toggle').click(function(){
// //     if (mobileBreakpoint.matches) {
// //         console.log('got here!!!')
// //         $('.dash-nav').toggleClass('mobile-show');
// //     } else {
// //         $('.dash').toggleClass('dash-compact');
// //     }
// // });
//   render() {
const Header = () => {
	return (
		<header className="dash-toolbar">
			<Link to="#!" className="menu-toggle">
				<i className="fas fa-bars" />
			</Link>
			<a href="#!" className="searchbox-toggle">
				<i className="fas fa-search" />
			</a>
			<form className="searchbox" action="#!">
				<a href="#!" className="searchbox-toggle">
					{" "}
					<i className="fas fa-arrow-left" />
				</a>
				<button type="submit" className="searchbox-submit">
					{" "}
					<i className="fas fa-search" />{" "}
				</button>
				<input type="text" className="searchbox-input" placeholder="type to search" />
			</form>
			<div className="tools">
				<a href="/" className="tools-item">
					<i className="fa fa-rotate-left" />
				</a>

				<Link to="/settings" className="tools-item goto-profile">
					<i className="fas fa-bell" />
					<i className="tools-item-count">0</i>
				</Link>
				<div className="dropdown tools-item">
					<a href="index.html" className="" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<i className="fas fa-user" />
					</a>
					<div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu1">
						<Link className="dropdown-item goto-profile" to="/settings">
							Profile
						</Link>
						<a
							className="dropdown-item"
							onClick={() => {
								sessionStorage.setItem("status", false);
								localStorage.removeItem("user");
							}}
							href="/">
							Logout
						</a>
					</div>
				</div>
			</div>
		</header>
	);
};
// }

// const mapStateToProps = state => ({});

// const mapDispatchToProps = {}

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Header);

export default Header;

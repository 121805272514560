import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import update from "react-addons-update";
import constants from "./actionConstants";
const _DEV = "localhost";
let URL_PATH = "/api/graphql";
if (window.location.hostname === _DEV) {
  URL_PATH = `http://localhost:4000/api/graphql`;
}

const client = new ApolloClient({
  uri: `${URL_PATH}`,
  request: (operation) => {
    const token = localStorage.getItem("token");
    operation.setContext({
      headers: {
        authorization: token ? token : "",
      },
    });
  },
});

const {
  GET_SHOW_CHANGE,
  GET_TODO,
  GET_USERSLIST,
  GET_APP_USERSLIST,
  GET_CAR_DETAILS,
  GET_ADMIN_STATUS,
  GET_EDIT_ME,
  GET_EDIT_USER,
  GET_ADD_NEW_USER,
  GET_NEW_ADMIN,
  GET_TRANSACTION_STATUS,
  GET_CREATE_TRANSACTION,
  GET_ACCOUNT_DETAILS,
  GET_ICAD_ACCOUNT_DETAILS,
  GET_USER_RECCURING,
  CREATE_SETUP_RECCURRING,
  GET_BALANCE_HISTORY,
  ERROR,
  PAGE_LOADING,
  GET_CHANGE_PASSWORD,
  GET_MANDATE_DETAILS,
  CREATE_SETUP_MANDATE,
  UPDATE_MANDATE_STATUS,
  CHARGE_AMOUNT,
} = constants;

export function appUsersLists() {
  const client = new ApolloClient({
    uri: `${URL_PATH}`,
  });
  return (dispatch) => {
    client
      .query({
        query: gql`
          query {
            getAppUsers {
              _id
              id
              name
              phone
              signed_up
              login
              info
              bvn
              bvn_skipped
            }
          }
        `,
      })
      .then(
        (response) => {
          dispatch({
            type: GET_APP_USERSLIST,
            payload: response.data.getAppUsers,
          });
          // console.log(response.data.users);
        },
        (err) => console.log("Theres error in json")
      );
  };
}
export function usersLists(payload) {
  return (dispatch) => {
    client
      .query({
        query: gql`
          query {
            getUsers(org_id: "${payload.org_id}", user_type: "${payload.user_type}") {
              _id
              id
              firstname
              lastname
              email
              phone
              status
              gender
              img_url
              user_type
              authorize
              org_id
              staff_id
              branch_code
              address {
                street
                suite
                city
              }
            }
          }
        `,
      })
      .then(
        (response) => {
          const result = response.data.getUsers;
          // console.log('result', result);
          dispatch({
            type: GET_USERSLIST,
            payload: result,
          });
          // console.log(response.data.users);
        },
        (err) => console.log("Theres error in json")
      );
  };
}

export function carDetailsLists() {
  const client = new ApolloClient({
    uri: `${URL_PATH}`,
  });
  return (dispatch) => {
    client
      .query({
        query: gql`
          query {
            getCarDetails {
              _id
              image_url
              plate_no
              car_name
              car_model
              available
              description
              created_at
              updated_at
            }
          }
        `,
      })
      .then(
        (response) => {
          dispatch({
            type: GET_CAR_DETAILS,
            payload: response.data.getCarDetails,
          });
          // console.log(response.data.users);
        },
        (err) => console.log("Theres error in json")
      );
  };
}
export function getTransaction(payload) {
  return (dispatch) => {
    client
      .query({
        query: gql`
          query {
            getTransac(org_id: "${payload.orgId}") {
              message
              error
              data {
                id
                name
                accountNumber
                amount
                trans_type
                teller_no
                created_at
              }
            }
          }
        `,
      })
      .then(
        (response) => {
          //console.log(response.data.getTransac);
          if (response.data.getTransac.error) {
            console.log(response.data.getTransac.message);
          }
          dispatch({
            type: GET_TRANSACTION_STATUS,
            payload: response.data.getTransac.data,
          });
          // console.log(response.data.users);
        },
        (err) => console.log("Theres error in json")
      );
  };
}

export function userLocation() {
  const client = new ApolloClient({
    uri: `${URL_PATH}`,
  });
  return (dispatch) => {
    client
      .query({
        query: gql`
          query {
            getUserLocation {
              _id
              location
            }
          }
        `,
      })
      .then(
        (response) => {
          dispatch({
            type: GET_CAR_DETAILS,
            payload: response.data.getCarDetails,
          });
          // console.log(response.data.users);
        },
        (err) => console.log("Theres error in json")
      );
  };
}

export function setAdminStatus(payload) {
  const client = new ApolloClient({
    uri: `${URL_PATH}`,
  });
  return (dispatch) => {
    client
      .query({
        query: gql`
        query{
          adminSignIn(email: "${payload.email}", password: "${payload.password}"){
        token
          user{
            _id
            id
            firstname
            lastname
            gender
            status
            email
            address{
              city
            }
            phone
            website
            img_url
            user_type
            org_id
            staff_id
          }
        }
        }
      `,
      })
      .then(
        (response) => {
          //console.log(response);
          dispatch({
            type: GET_ADMIN_STATUS,
            payload: response,
          });
        },
        (error) => {
          const err = {
            error: error.networkError.result,
          };
          dispatch({
            type: GET_ADMIN_STATUS,
            payload: err,
          });
        }
      );
  };
}
export function editMyProfile(payload) {
  //console.log('logging payload and token', payload);
  return (dispatch) => {
    client
      .mutate({
        mutation: gql`
        mutation{
          updateAdmin(
            _id:"${payload._id}",
            firstname: "${payload.firstname}",
            lastname: "${payload.lastname}",
            email: "${payload.email}",
            phone: "${payload.phone}",
            gender: "${payload.gender}"){
            _id
            id
            firstname
            lastname
            gender
            status
            email
            address{
              city
            }
            phone
            website
            img_url
            user_type
            authorize
            org_id
            staff_id
            branch_code
            }
        }
      `,
      })
      .then(
        (response) => {
          dispatch({
            type: GET_EDIT_ME,
            payload: response,
          });
        },
        (error) => {
          const err = {
            error: error.networkError.result,
          };
          dispatch({
            type: GET_EDIT_ME,
            payload: err,
          });
        }
      );
  };
}
export function editUserInfo(payload) {
  //console.log('logging user payload', payload);
  return (dispatch) => {
    client
      .mutate({
        mutation: gql`
        mutation{
          updateUser(
            _id:"${payload._id}",
            firstname: "${payload.firstname}",
            lastname: "${payload.lastname}",
            email: "${payload.email}",
            phone: "${payload.phone}",
            org_id: "${payload.org_id}",
            staff_id: "${payload.staff_id}",
            branch_code: "${payload.branch_code}",
            user_type: "${payload.user_type}",
            authorize: ${payload.authorize},
            gender: "${payload.gender}",
            password: "${payload.password}",
            address: {
              city: "${payload.address.city}"
            }),{
            _id
            id
            firstname
            lastname
            gender
            email
            address{
              city
            }
            phone
            user_type
            authorize
            org_id
            staff_id
            branch_code
            }
        }
      `,
      })
      .then(
        (response) => {
          console.log(response.data.updateUser, "USER UPDATE");
          dispatch({
            type: GET_EDIT_USER,
            payload: response.data.updateUser,
          });
        },
        (error) => {
          const err = {
            error: error.networkError.result,
          };
          dispatch({
            type: GET_EDIT_USER,
            payload: err,
          });
        }
      );
  };
}
export function addNewUser(payload) {
  return (dispatch) => {
    client
      .mutate({
        mutation: gql`
        mutation createAdmin{
          createAdmin(
            firstname: "${payload.firstname}",
            lastname: "${payload.lastname}",
            email: "${payload.email}",
            phone: "${payload.phone}",
            password: "${payload.password}",
            user_type: "${payload.user_type}",
            added_by: "${payload._id}",
            org_id: "${payload.org_id}",
            staff_id: "${payload.staff_id}",
            branch_code: "${payload.branch_code}",
            gender: "${payload.gender}"){
            _id
            id
            firstname
            lastname
            gender
            status
            email
            address{
              city
            }
            phone
            website
            img_url
            user_type
            }
        }
      `,
      })
      .then(
        (response) => {
          dispatch({
            type: GET_ADD_NEW_USER,
            payload: response,
          });
        },
        (error) => {
          const err = {
            error: error.networkError.result,
          };
          dispatch({
            type: GET_ADD_NEW_USER,
            payload: err,
          });
        }
      );
  };
}

export function createNewAdmin(payload) {
  return (dispatch) => {
    client
      .mutate({
        mutation: gql`
        mutation{
          createAdmin(
            username: "${payload.username}",
            password: "${payload.password}",
            firstname: "${payload.firstname}",
            lastname: "${payload.lastname}",
            email: "${payload.email}",
            user_type: "${payload.user_type}",
            gender: "${payload.gender}"){
        }
        }
      `,
      })
      .then(
        (response) => {
          dispatch({
            type: GET_NEW_ADMIN,
            payload: response.data.createAdmin,
          });
        },
        (error) => {
          const err = {
            error: error.networkError.result,
          };
          dispatch({
            type: GET_ADMIN_STATUS,
            payload: err,
          });
        }
      );
  };
}
export function changePassword(payload) {
  return (dispatch) => {
    client
      .mutate({
        mutation: gql`
        mutation{
          changePassword(
            _id: "${payload._id}",
            old_password: "${payload.old_password}",
            new_password: "${payload.new_password}")
      }
      `,
      })
      .then(
        (response) => {
          //console.log('response', response);

          dispatch({
            type: GET_CHANGE_PASSWORD,
            payload: response,
          });
        },
        (error) => {
          const err = {
            error: error.networkError.result,
          };
          dispatch({
            type: GET_CHANGE_PASSWORD,
            payload: err,
          });
        }
      );
  };
}
export function createTransaction(payload) {
  return (dispatch) => {
    client
      .mutate({
        mutation: gql`
          mutation {
            createTransac (
              account_name: "${payload.account_name}",
              accountNumber: "${payload.accountNumber}",
              amount: "${payload.amount}"
              trans_type: "${payload.trans_type}"
              teller_no: "${payload.teller_no}"
              user_id: "${payload.user_id}"
              phone: "${payload.phone}"
            )
          }
        `,
      })
      .then(
        (response) => {
          dispatch({
            type: GET_CREATE_TRANSACTION,
            payload: response,
          });
        },
        (error) => {
          dispatch({
            type: GET_CREATE_TRANSACTION,
            payload: error,
          });
        }
      );
  };
}

export function updateMandateStatus(payload) {
  return (dispatch) => {
    client
      .mutate({
        mutation: gql`
        mutation{
          updateMandateStatus(
            org_id: "${payload.orgId}",
            mandateCode: "${payload.mandateCode}"
            ){
              message
            }
        }
      `,
      })
      .then(
        (response) => {
          dispatch({
            type: UPDATE_MANDATE_STATUS,
            payload: response.data.updateMandateStatus,
          });
        },
        (error) => {
          dispatch({
            type: UPDATE_MANDATE_STATUS,
            payload: error,
          });
        }
      );
  };
}

export const chargeAmount = (payload) => {
  const client = new ApolloClient({
    uri: `${URL_PATH}`,
  });
  return async (dispatch) => {
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation {
            chargeAmount (
              token: "${payload.token}",
              user_id: "${payload.userId}"
              phone_id: "${payload.phoneId}"
			  status: "${payload.status}"
              amount: "${payload.chargeAmt}"
              org_id: "${payload.orgId}"
			  mandate_id: "${payload.idCharge}"
              initiator_id: "${payload.initiatorID}"
              initiator_name: "${payload.initiatorName}"
            ) {
              status
              message
              error
            }
          }
        `,
      });

      dispatch({
        type: CHARGE_AMOUNT,
        payload: response.data.chargeAmount,
      });
    } catch (error) {
      throw new Error(error);
    }
  };
};

// export function createMandate(payload) {
// 	console.log("payload", payload);
// 	return (dispatch) => {
// 		client
// 			.mutate({
// 				mutation: gql`
//         mutation{
//           createMandate(
//             id: "${payload.id}",
//             org_id: "${payload.orgId}",
//             accountNumber: "${payload.accountNumber}",
//             bankCode: "${payload.bankCode}",
//             payerName: "${payload.payerName}",
//             accountName: "${payload.accountName}",
//             amount: "${payload.amount}",
// 			email_mandate: "${payload.email_mandate}",
// 			phone_mandate: "${payload.phone_mandate}",
//             ){
//               message
//             }
//         }
//       `,
// 			})
// 			.then(
// 				(response) => {
// 					dispatch({
// 						type: CREATE_SETUP_MANDATE,
// 						payload: response.data.createMandate,
// 					});
// 				},
// 				(error) => {
// 					//console.log('hello error', error);
// 					// const err = {
// 					//   error: error.networkError.result,
// 					// };
// 					dispatch({
// 						type: CREATE_SETUP_MANDATE,
// 						payload: error,
// 					});
// 				}
// 			);
// 	};
// }

export function createMandate(payload) {
  console.log("payload", payload);
  return async (dispatch) => {
    const response = await client.mutate({
      mutation: gql`
        mutation{
          createMandate(
            id: "${payload.id}",
            org_id: "${payload.orgId}",
            accountNumber: "${payload.accountNumber}",
            bankCode: "${payload.bankCode}",
            payerName: "${payload.payerName}",
            accountName: "${payload.accountName}",
            amount: "${payload.amount}",
            email_mandate: "${payload.email_mandate}",
            phone_mandate: "${payload.phone_mandate}",
            ){
              message
            }
        }
      `,
    });
    // .then(
    // 	(response) => {
    // 		dispatch({
    // 			type: CREATE_SETUP_MANDATE,
    // 			payload: response.data.createMandate,
    // 		});
    // 	},
    // 	(error) => {
    // 		//console.log('hello error', error);
    // 		// const err = {
    // 		//   error: error.networkError.result,
    // 		// };
    // 		dispatch({
    // 			type: CREATE_SETUP_MANDATE,
    // 			payload: error,
    // 		});
    // 	}
    // );
    console.log("mandate response", response);
    return response.data.createMandate;
  };
}

// export function getMandate(payload) {
// 	console.log("getting mandate data", payload)
// 	return (dispatch) => {
// 		client
// 			.query({
// 				query: gql`
//           query {
//             getAllMandateDetails(id: "${payload.account_id}") {
//               id,
//               accountNumber,
//               bankCode,
//               payerName,
//               accountName,
//               amount,
//               mandateCode,
//               responseCode,
//               responseDescription,
//               workFlowStatusDescription,
//             }
//           }
//         `,
// 			})
// 			.then(
// 				(response) => {
// 					//console.log(response.data.getAllIcadAccountDetails);
// 					dispatch({
// 						type: GET_MANDATE_DETAILS,
// 						payload: response.data.getAllMandateDetails,
// 					});
// 					// console.log(response.data.users);
// 				},
// 				(err) => console.log("Theres error in json")
// 			);
// 	};
// }

export function getMandate(payload) {
  console.log("getting mandate data", payload);
  return async (dispatch) => {
    const response = await client.query({
      query: gql`
          query {
            getAllMandateDetails(id: "${payload.account_id}") {
              id,
              accountNumber,
              bankCode,
              payerName,
              accountName,
              amount,
              mandateCode,
              responseCode,
              responseDescription,
              workFlowStatusDescription,
            }
          }
        `,
      fetchPolicy: "network-only",
    });
    // .then(
    // 	(response) => {
    // 		//console.log(response.data.getAllIcadAccountDetails);
    // 		dispatch({
    // 			type: GET_MANDATE_DETAILS,
    // 			payload: response.data.getAllMandateDetails,
    // 		});
    // 		// console.log(response.data.users);
    // 	},
    // 	(err) => console.log("Theres error in json")
    // );
    console.log("response mandate data", response.data.getAllMandateDetails);
    return response.data.getAllMandateDetails;
  };
}

export function nddAutoRepayment(payload) {
  const dates = new Date();
  let timeString = dates.toISOString().substr(0, 19);
  timeString = timeString.replace(/:/g, "");
  timeString = timeString.replace(/-/g, "");
  let queryConcat = "";
  queryConcat += `, timeString: "${timeString}" `;
  if (payload.status !== "" && payload.status !== undefined) {
    queryConcat += `, status: "${payload.status}" `;
  }
  if (payload.limit !== "" && payload.limit !== undefined) {
    queryConcat += `, limit: ${payload.limit} `;
  }
  if (payload.offset !== "" && payload.offset !== undefined) {
    queryConcat += `, offset: ${payload.offset} `;
  }
  if (payload.dateFilter !== "" && payload.dateFilter !== undefined) {
    queryConcat += `, date_filter: "${payload.dateFilter}" `;
  }
  if (payload.startDate !== "" && payload.startDate !== undefined) {
    queryConcat += `, start_date: "${payload.startDate}" `;
  }
  if (payload.endDate !== "" && payload.endDate !== undefined) {
    queryConcat += `, end_date: "${payload.endDate}" `;
  }
  if (payload.specificDate !== "" && payload.specificDate !== undefined) {
    queryConcat += `, specific_date: "${payload.specificDate}" `;
  }
  if (payload.queryFilter !== "" && payload.queryFilter !== undefined) {
    queryConcat += `, query_filter: "${payload.queryFilter}" `;
  }
  return async (dispatch) => {
    try {
      const response = await client.query({
        query: gql`
          query {
            getAllIcadAccountDetails(org_id: "${payload.orgId}" ${queryConcat}) {
            status  
            data{
                id
                accountName
                accountNumber
                bankName
                accountDesignationName
                accountTypeName
                bvn
                created_at
                bankCode,
				cif,
				accountStatus
				
              }
              pagination{
              offset
              limit
              total_count
              }
              error
              message
            }
          }
        `,
      });
      return response.data.getAllIcadAccountDetails;
    } catch (err) {
      throw new Error(err);
      // dispatch({
      //   type: GET_ACCOUNT_NAME,
      //   payload: err
      // });
    }
  };
}

export function autoRepayment(payload) {
  return (dispatch) => {
    client
      .query({
        query: gql`
          query {
            getAllAccountDetails(org_id: "${payload.orgId}") {
              account_name
              card
              account_number
              created_at
              payment_id
            }
          }
        `,
      })
      .then(
        (response) => {
          //console.log(response.data.getAllAccountDetails);
          dispatch({
            type: GET_ACCOUNT_DETAILS,
            payload: response.data.getAllAccountDetails,
          });
          // console.log(response.data.users);
        },
        (err) => console.log("Theres error in json")
      );
  };
}

export function getReccurring(payload, network = false) {
  return async (dispatch) => {
    const queryVar = {
      query: gql`
        query {
           getRecurring(payment_id:"${payload}") {
            payment_id
            loan_amount
            repayment_amount
            day_of_the_month
            created_at
            frequency
          }
        }
      `,
    };
    if (network) queryVar.fetchPolicy = "network-only";

    const response = await client.query(queryVar);

    console.log("response data", response);

    return response.data.getRecurring;
    /*
      .then(
        response => {
          //console.log('got too get reccurring', response.data.getRecurring);
          dispatch({
            type: GET_USER_RECCURING,
            payload: response.data.getRecurring,
          });
          // console.log(response.data.users);
        },
        err => console.log('Theres error in json')
      );
      */
  };
}

export function createSetupReccurring(payload) {
  return async (dispatch) => {
    const response = await client.mutate({
      mutation: gql`
        mutation{
          createRecurring(
            payment_id: "${payload.payment_id}",
            loan_amount: "${payload.loan_amount}",
            repayment_amount: "${payload.repayment_amount}",
            day_of_the_month: "${payload.day_of_the_month}",
            frequency: "${payload.frequency}",
            ){
              message
            }
        }
      `,
    });

    return response.data.createRecurring;
    /*
      .then(
        response => {
          //console.log('hello response', response);
          dispatch({
            type: CREATE_SETUP_RECCURRING,
            payload: response.data.createRecurring,
          });
        },
        error => {
          //console.log('hello error', error);
          // const err = {
          //   error: error.networkError.result,
          // };
          dispatch({
            type: CREATE_SETUP_RECCURRING,
            payload: error,
          });
        }
      );
      */
  };
}
export function getAllBalanceHistory(payload) {
  let queryConcat = "";

  if (payload.dateFilter !== "" && payload.dateFilter !== undefined) {
    queryConcat += `, date_filter: "${payload.dateFilter}" `;
  }

  if (payload.specificDate !== "" && payload.specificDate !== undefined) {
    queryConcat += `, specific_date: "${payload.specificDate}" `;
  }

  if (payload.startDate !== "" && payload.startDate !== undefined) {
    queryConcat += `, start_date: "${payload.startDate}" `;
  }

  if (payload.endDate !== "" && payload.endDate !== undefined) {
    queryConcat += `, end_date: "${payload.endDate}" `;
  }

  if (payload.trancType !== "" && payload.trancType !== undefined) {
    queryConcat += `, tranc_type: "${payload.trancType}" `;
  }

  return (dispatch) => {
    dispatch({
      type: PAGE_LOADING,
      payload: true,
    });
    client
      .query({
        query: gql`
          query {
            getBalanceHistory(org_id: "${payload.orgId}", limit: ${payload.limit}, offset: ${payload.offset} ${queryConcat}) {
              data {
                id
                user_id
                balance
                balance_before
                amount
                currency
                transaction_type
                reason
                credit
                created_at
              }
              pagination {
                total_count
                limit
                offset
              }
              error
            }
          }
        `,
      })
      .then(
        (response) => {
          if (!response.data.getBalanceHistory.error) {
            dispatch({
              type: GET_BALANCE_HISTORY,
              payload: response.data.getBalanceHistory,
            });
          } else {
            dispatch({
              type: ERROR,
              payload: response.data.getBalanceHistory.error,
            });
            //console.log(response.data.getBalanceHistory.error);
          }
          dispatch({
            type: PAGE_LOADING,
            payload: false,
          });
        },
        (err) => console.log("Theres error in json", err)
      )
      .catch((err) => {
        dispatch({
          type: PAGE_LOADING,
          payload: false,
        });
      });
  };
}
export function fetchLists() {
  return (dispatch) => {
    fetch("https://jsonplaceholder.typicode.com/todos")
      .then(
        (response) => {
          //console.log(response);
          return response.json();
        },
        (err) => console.log("Theres error in response")
      )
      .then(
        (json) => {
          dispatch({
            type: GET_TODO,
            payload: json,
          });
          //console.log(json);
        },
        (err) => console.log("Theres error in json")
      );
  };
}

export function updateCif(payload) {
	console.log("Cif payload", payload);
	return async (dispatch) => {
		const response = await client.mutate({
			mutation: gql`
        mutation{
        createIcadAccountCif(
            org_id: "${payload.org_id}",
            cif: "${payload.cif}",
            icadId: "${payload.icadId}"
            ){
              message
            }
        }
      `,
		});

		console.log("response data", response.data.createIcadAccountCif);

		return response.data.createIcadAccountCif;
	};
	// return (dispatch) => {
	// 	client
	// 		.mutate({
	// 			mutation: gql`
	//     mutation{
	//   createIcadAccountCif(
	//     id: "${payload.id}",
	//     org_id: "${payload.orgId}",
	//     cif: "${payload.cif}",
	//     icadId: "${payload.icadId}"
	// 		){
	// 		  message
	// 		}

	//     }
	//   `,
	// 		})
	// 		.then(
	// 			(response) => {
	// 				console.log("hellresponse", response);
	// 				dispatch({
	// 					type: CREATE_SETUP_MANDATE,
	// 					payload: response.data.createMandate,
	// 				});
	// 			},
	// 			(error) => {
	// 				//console.log('hello error', error);
	// 				// const err = {
	// 				//   error: error.networkError.result,
	// 				// };

	// 				dispatch({
	// 					type: CREATE_SETUP_MANDATE,
	// 					payload: error,
	// 				});
	// 			}
	// 		);
	// };
}

export function showChange(name) {
  return (dispatch) => {
    const newName = { name: `new name is ${name} and newName` };
    dispatch({
      type: GET_SHOW_CHANGE,
      payload: newName,
    });
  };
}

function handleShowChange(state, action) {
  return update(state, {
    showChangeUpdate: {
      $set: action.payload,
    },
  });
}

function handleTodo(state, action) {
  return update(state, {
    showTodo: {
      $set: action.payload,
    },
  });
}

function handleUsersLists(state, action) {
  return update(state, {
    showUserLists: {
      $set: action.payload,
    },
  });
}

function handleAppUsersLists(state, action) {
  return update(state, {
    showAppUserLists: {
      $set: action.payload,
    },
  });
}

function handleCarDetailsLists(state, action) {
  return update(state, {
    showCarDetailsLists: {
      $set: action.payload,
    },
  });
}

function handleAdminStatus(state, action) {
  return update(state, {
    showAdminStatus: {
      $set: action.payload,
    },
  });
}

function handleEditMe(state, action) {
  return update(state, {
    showEditMe: {
      $set: action.payload,
    },
  });
}

function handleEditUser(state, action) {
  return update(state, {
    showEditUser: {
      $set: action.payload,
    },
  });
}

function handleNewUser(state, action) {
  return update(state, {
    showNewUser: {
      $set: action.payload,
    },
  });
}

function handleNewAdmin(state, action) {
  return update(state, {
    showNewAdmin: {
      $set: action.payload,
    },
  });
}

function handleGetTransactions(state, action) {
  return update(state, {
    showGetTransaction: {
      $set: action.payload,
    },
  });
}
function handleCreateTransactions(state, action) {
  return update(state, {
    showCreateTransaction: {
      $set: action.payload,
    },
  });
}
function handlAllAccountDetails(state, action) {
  return update(state, {
    showAllAccountDetails: {
      $set: action.payload,
    },
  });
}

function handlAllIcadAccountDetails(state, action) {
  return update(state, {
    showAllIcadAccountDetails: {
      $set: action.payload,
    },
  });
}

function handlAllMandateDetails(state, action) {
  return update(state, {
    showAllMandateDetails: {
      $set: action.payload,
    },
  });
}

function handleUserReccuring(state, action) {
  return update(state, {
    showUserReccurring: {
      $set: action.payload,
    },
  });
}
function handleSetupReccuring(state, action) {
  return update(state, {
    showSetupReccurring: {
      $set: action.payload,
    },
  });
}

function handleSetupMandate(state, action) {
  return update(state, {
    showSetupMandate: {
      $set: action.payload,
    },
  });
}

function handleUpdateMandate(state, action) {
  return update(state, {
    showUpdateMandate: {
      $set: action.payload,
    },
  });
}

function handleAllBalanceHistory(state, action) {
  return update(state, {
    showAllBalanceHistory: {
      $set: action.payload,
    },
  });
}

function handleAllChangePassword(state, action) {
  return update(state, {
    showChangePassword: {
      $set: action.payload,
    },
  });
}

const handleChargeAmount = (state, action) => {
  return update(state, {
    chargeAmountResponse: {
      $set: action.payload,
    },
  });
};

const ACTION_HANDLERS = {
  CHARGE_AMOUNT: handleChargeAmount,
  GET_SHOW_CHANGE: handleShowChange,
  GET_TODO: handleTodo,
  GET_USERSLIST: handleUsersLists,
  GET_APP_USERSLIST: handleAppUsersLists,
  GET_CAR_DETAILS: handleCarDetailsLists,
  GET_ADMIN_STATUS: handleAdminStatus,
  GET_EDIT_ME: handleEditMe,
  GET_EDIT_USER: handleEditUser,
  GET_ADD_NEW_USER: handleNewUser,
  GET_NEW_ADMIN: handleNewAdmin,
  GET_TRANSACTION_STATUS: handleGetTransactions,
  GET_CREATE_TRANSACTION: handleCreateTransactions,
  GET_ACCOUNT_DETAILS: handlAllAccountDetails,
  GET_ICAD_ACCOUNT_DETAILS: handlAllIcadAccountDetails,
  GET_MANDATE_DETAILS: handlAllMandateDetails,
  GET_USER_RECCURING: handleUserReccuring,
  CREATE_SETUP_RECCURRING: handleSetupReccuring,
  CREATE_SETUP_MANDATE: handleSetupMandate,
  UPDATE_MANDATE_STATUS: handleUpdateMandate,
  GET_BALANCE_HISTORY: handleAllBalanceHistory,
  GET_CHANGE_PASSWORD: handleAllChangePassword,
};

const initialState = {};

export default function HomeReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

import React, { Component } from "react";
import { getAllBalanceHistory } from "../../../reducers/home";
import { connect } from "react-redux";
import HistoryForm from "./commons/historyForm";
import Loader from "./Loader";
import { setErrorMessage } from "./../../../actions/error";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import FilterView from "./FilterView";
import Pagination from "./Pagination";
class BalanceHistory extends Component {
	state = {
		balances: [],
		details: [],
		pageSize: 10,
		currentPage: 1,
		orgId: "",
		offset: 0,
		filterDetails: {
			trancType: "",
			dateFilter: "",
			specificDate: "",
			startDate: "",
			endDate: "",
		},
	};

	notify = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_CENTER,
			autoClose: true,
			onClose: this.props.setErrorMessage(null),
		});
	};

	setFilterDetails = (filterDetails) => {
		this.setState({ filterDetails });
	};

	submitFilter = () => {
		const { orgId, pageSize, offset, filterDetails } = this.state;
		const payload = {
			orgId,
			limit: pageSize || null,
			offset: offset,
			...filterDetails,
		};
		try {
			this.props.getAllBalanceHistory(payload);
		} catch (err) {
			console.log(err);
		}
	};

	componentDidMount = () => {
		const { user } = window.localStorage;
		const { org_id: orgId } = JSON.parse(user);
		const offset = (this.state.currentPage - 1) * this.state.pageSize;
		this.setState({ orgId, offset });
		const err = this.props.getAllBalanceHistory({
			orgId,
			limit: this.state.pageSize || null,
			offset: offset || 0,
		});
		if (err) {
			console.log(err);
		}
	};

	populateBankHistory = (showAllBalanceHistory) => {
		console.log("got here", showAllBalanceHistory);
		const transactionsList =
			showAllBalanceHistory &&
			showAllBalanceHistory.data.map((bankHistory) => {
				const { id, credit, amount, balance, balance_before, transaction_type, reason, created_at } = bankHistory;
				const type =
					credit === "false" ? (
						<span className="text-danger">
							<i className="fas fa-arrow-down"></i> out
						</span>
					) : (
						<span className="text-success">
							<i className="fas fa-arrow-up"></i> in
						</span>
					);
				return (
					<tr key={id}>
						<td>{type}</td>
						<td>{amount}</td>
						<td>{`₦${balance}`}</td>
						<td>{`₦${balance_before}`}</td>
						<td>
							{transaction_type} {reason}
						</td>
						<td>{created_at}</td>
					</tr>
				);
			});
		return transactionsList;
	};
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.showAllBalanceHistory !== this.props.showAllBalanceHistory) {
			console.log("update", this.props.showAllBalanceHistory);
		}
	}

	handlePageChange = (page) => {
		this.setState({ currentPage: page });
		const { orgId } = this.state;
		const offset = (page - 1) * this.state.pageSize;
		this.props.getAllBalanceHistory({
			orgId,
			limit: this.state.pageSize || null,
			offset: offset || 0,
		});
		// this.props.getAllBalanceHistory(page - 1);
		// this.setState({ currentPage: page });
	};

	onFilter = (data) => {
		const { orgId } = this.state;
		for (let propName in data) {
			if (data[propName] === null || data[propName] === undefined || data[propName] == "") {
				delete data[propName];
			}
		}
		if (data.dateFilter == "interval") {
			if (!data.startDate || !data.endDate) {
				this.notify("Please specify both the start and end date");
				return;
			}
			const exportToCSVLink = `https://api.jekalo.com/api/balance/csv_export?org_id=${orgId}&date_filter=${data.dateFilter}&start_date=${data.startDate}&end_date=${data.endDate}`;
			// console.log(exportToCSVLink);
			window.location.href = exportToCSVLink;
		} else if (data.dateFilter == "specific") {
			if (!data.specificDate) {
				this.notify("Please specify the specific date");
				return;
			}
			const exportToCSVLink = `https://api.jekalo.com/api/balance/csv_export?org_id=${orgId}&date_filter=specific&specific_date=${data.specificDate}`;
			// console.log(exportToCSVLink);
			window.location.href = exportToCSVLink;
		} else if (data.dateFilter == "today") {
			const exportToCSVLink = `https://api.jekalo.com/api/balance/csv_export?org_id=${orgId}&date_filter=today`;
			// console.log(exportToCSVLink);
			window.location.href = exportToCSVLink;
		} else {
			let exportToCSVLink = `https://api.jekalo.com/api/balance/csv_export?org_id=${orgId}`;
			window.location.href = exportToCSVLink;
			// console.log(exportToCSVLink);
		}
	};
	render() {
		const { showAllBalanceHistory } = this.props;
		this.props.error && this.notify(this.props.error);
		const totalItemsCount = showAllBalanceHistory ? showAllBalanceHistory.pagination.total_count : this.state.pageSize;
		return (
			<div className="p-4">
				{this.props.loading && <Loader />}
				<div className="dropdown">
					<button
						className="btn btn-secondary dropdown-toggle"
						type="button"
						id="dropdownMenuButton"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false">
						Filter
					</button>
					<div className="dropdown-menu p-3" aria-labelledby="dropdownMenuButton" style={{ width: 300 }}>
						<div>
							<HistoryForm filterDetails={this.state.filterDetails} setFilterDetails={this.setFilterDetails} handleSubmit={this.submitFilter} />
						</div>
					</div>
				</div>
				<FilterView onFilter={this.onFilter} label={"Export as CSV"} exclude={["keyword", "status"]} />
				<table className="table">
					<thead>
						<tr>
							<th>Type</th>
							<th>Amount</th>
							<th>Balance Before</th>
							<th>Balance After</th>
							<th>Detail</th>
							<th>Date</th>
						</tr>
					</thead>
					<tbody>{this.populateBankHistory(this.props.showAllBalanceHistory)}</tbody>
				</table>

				<Pagination
					pageHistory={{
						currentPage: this.state.currentPage,
						totalPages: Math.ceil(totalItemsCount / this.state.pageSize),
					}}
					onPageChange={(page) => {
						this.handlePageChange(page);
					}}></Pagination>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	showAllBalanceHistory: state.home.showAllBalanceHistory,
	loading: state.loading.loading,
	error: state.loading.error,
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ setErrorMessage, getAllBalanceHistory }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BalanceHistory);

import React from "react";
import { Link, NavLink } from "react-router-dom";
import maxisoft from "../../assets/images/maxisoft_logo_jekalo_reverse.png";
import haggai from "../../assets/images/haggailogo-reverse.png";
import { viewPermission, exactViewPermission, orgAllowed, isHaggai, brandname, orgNotAllowed, isBroad } from "../../actions/permission";
import getImageByKey from "../getImageByKey";
import BalanceHistory from "../MainNav/Transactions/balanceHistory";

// class SideNav extends Component {
//   render() {

const SideNav = () => {
	return (
		<div className="dash-nav dash-nav-dark">
			<header>
				<Link to="#!" className="menu-toggle">
					<i className="fas fa-times" />
				</Link>
				<Link to="/dashboard">
					<img src={getImageByKey(brandname())} alt="" width="155px" />
				</Link>
			</header>
			<nav className="dash-nav-list">
				{/* <NavLink
          activeClassName="active-bar"
          to="/dashboard"
          id="#dashboard"
          className="dash-nav-item"
        >
          <i className="fas fa-home" /> Overview{" "}
        </NavLink> */}

				<NavLink to="/dashboard" id="#dashboard2" activeClassName="active-bar" className="dash-nav-item">
					<i className="fas fa-hourglass-start" /> Dashboard{" "}
				</NavLink>
				{viewPermission("transactions") && (
					<div>
						<a
							className="dash-nav-item"
							data-toggle="collapse"
							href="#collapseExample2"
							role="button"
							aria-expanded="false"
							aria-controls="collapseExample2">
							<i className="fas fa-exchange-alt" />
							Transactions
						</a>
						<div className="collapse" id="collapseExample2">
							{!orgNotAllowed("") && (
								<NavLink activeClassName="active-bar" to="/transactions/ndd-autorepayment" className="dash-nav-item">
									<i className="fas fa-file-invoice-dollar light-success" />
									<span className="light-success ">NDD Auto Repayment</span>
								</NavLink>
							)}

							{!orgNotAllowed("") && (
								<NavLink activeClassName="active-bar" to="/transactions/autorepayment" className="dash-nav-item">
									<i className="fas fa-file-invoice-dollar light-success" />
									<span className="light-success ">Auto Repayment</span>
								</NavLink>
							)}

							{!orgNotAllowed("") && (
								<NavLink activeClassName="active-bar" to="/transactions/statement" className="dash-nav-item">
									<i className="fas fa-file-invoice-dollar light-success" />
									<span className="light-success ">Bank Statement</span>
								</NavLink>
							)}

							{!orgNotAllowed("") && (
								<NavLink activeClassName="active-bar" to="/transactions/teller" className="dash-nav-item">
									<i className="fas fa-file-invoice light-success" />
									<span className="light-success ">User/Staff Posting</span>
								</NavLink>
							)}

							{!orgNotAllowed("") && (
								<NavLink activeClassName="active-bar" to="/account/customer" className="dash-nav-item">
									<i className="fas fa-university light-success" />
									<span className="light-success">Account Opening</span>
								</NavLink>
							)}

							{!orgNotAllowed("") && (
								<NavLink activeClassName="active-bar" to="/transactions/balanceHistory" className="dash-nav-item">
									<i className="fas fa-money-check light-success" /> <span className="light-success ">Balance History</span>
								</NavLink>
							)}

							{!orgNotAllowed("") && (
								<NavLink activeClassName="active-bar" to="/transactions/cheque" className="dash-nav-item">
									<i className="fas fa-file-invoice light-success" /> <span className="light-success ">Non-Cash Entry</span>
								</NavLink>
							)}

							{!orgNotAllowed("") && (
								<NavLink activeClassName="active-bar" to="/transactions/cash" className="dash-nav-item">
									<i className="fas fa-file-invoice-dollar light-success" /> <span className="light-success ">Cash Entry</span>
								</NavLink>
							)}

							{(isBroad("") || orgNotAllowed("") || !exactViewPermission("approvals")) && (
								<NavLink activeClassName="active-bar" to="/transactions/transfer" className="dash-nav-item">
									<i className="fas fa-expand-arrows-alt light-success" />
									<span className="light-success">Transfer </span>
								</NavLink>
							)}

							{viewPermission("approvals") && (
								<NavLink activeClassName="active-bar" to="/transactions/batch-manage" className="dash-nav-item">
									<i className="fas fa-expand-arrows-alt light-success" />
									<span className="light-success">Approvals</span>
								</NavLink>
							)}

							{!orgNotAllowed("") && viewPermission("approvals") && (
								<NavLink activeClassName="active-bar" to="/transactions/bills-payment" className="dash-nav-item">
									<i className="fas fa-expand-arrows-alt light-success" />
									<span className="light-success">Bills payment</span>
								</NavLink>
							)}
						</div>
					</div>
				)}

				{!orgNotAllowed("") && (
					<div>
						<a
							className="dash-nav-item"
							data-toggle="collapse"
							href="#collapseExample3"
							role="button"
							aria-expanded="false"
							aria-controls="collapseExample3">
							<i className="fas fa-file" />
							Report
						</a>
						<div className="collapse" id="collapseExample3">
							<NavLink activeClassName="active-bar" to="/transactions/accountOpening" className="dash-nav-item">
								<i className="fas fa-user-circle light-success" />
								<span className="light-success">Current Transactions</span>
							</NavLink>

							<NavLink activeClassName="active-bar" to="/account/customer" className="dash-nav-item">
								<i className="fas fa-university light-success" />
								<span className="light-success">Account Balance</span>
							</NavLink>
						</div>
					</div>
				)}

				{!orgNotAllowed("") && (
					<NavLink activeClassName="active-bar" to="/transactions/accountOpening" className="dash-nav-item">
						<i className="fas fa-user-circle" /> Account
					</NavLink>
				)}
				{orgAllowed("") && (
					<div>
						<a className="dash-nav-item" data-toggle="collapse" href="#collapseLoan" role="button" aria-expanded="false" aria-controls="collapseLoan">
							<i className="fas fa-file-invoice" />
							Loan
						</a>
						<div className="collapse" id="collapseLoan">
							<NavLink activeClassName="active-bar" to="/loan/salary" className="dash-nav-item">
								<i className="fas fa-file-invoice light-success " />
								<span className="light-success ">Salary</span>
							</NavLink>
							<NavLink activeClassName="active-bar" to="/loan/customerloan" className="dash-nav-item">
								<i className="fas fa-file-invoice-dollar light-success" />
								<span className="light-success ">Loan Disbursement</span>
							</NavLink>
						</div>
					</div>
				)}

				{!orgNotAllowed("") && (
					<div>
						<a
							className="dash-nav-item"
							data-toggle="collapse"
							href="#collapseExample4"
							role="button"
							aria-expanded="false"
							aria-controls="collapseExample4">
							<i className="fas fa-exchange-alt" />
							Main
						</a>
						<div className="collapse" id="collapseExample4">
							<NavLink activeClassName="active-bar" to="/main/trip" className="dash-nav-item">
								<i className="fas fa-file-invoice-dollar light-success" />
								<span className="light-success ">Driver</span>
							</NavLink>
							<NavLink activeClassName="active-bar" to="/transactions/teller" className="dash-nav-item">
								<i className="fas fa-file-invoice light-success" />
								<span className="light-success ">Teller Postings</span>
							</NavLink>
							<NavLink activeClassName="active-bar" to="/transactions/balanceHistory" className="dash-nav-item">
								<i className="fas fa-money-check light-success" /> <span className="light-success ">Balance History</span>
							</NavLink>
						</div>
					</div>
				)}
				{viewPermission("usersInfo") && (
					<NavLink activeClassName="active-bar" to="/usersinfo" className="dash-nav-item">
						<i className="fas fa-users" /> Users Info{" "}
					</NavLink>
				)}

				{!orgNotAllowed("") && viewPermission("approvals") && (
					<div>
						<a
							className="dash-nav-item"
							data-toggle="collapse"
							href="#collapseExample10"
							role="button"
							aria-expanded="false"
							aria-controls="collapseExample10">
							<i className="far fa-comment-alt" />
							Bulk SMS
						</a>
						<div className="collapse" id="collapseExample10">
							<NavLink activeClassName="active-bar" to="/bulk-sms/new" className="dash-nav-item">
								<i className="far fa-comment-alt light-success"></i>
								<span className="light-success">Send Bulk SMS</span>
							</NavLink>
							<NavLink activeClassName="active-bar" to="/bulk-sms/history" className="dash-nav-item">
								<i className="fas fa-history light-success"></i>
								<span className="light-success">SMS history</span>
							</NavLink>
						</div>
					</div>
				)}

				{!orgNotAllowed("") && viewPermission("appUser") && (
					<NavLink activeClassName="active-bar" to="/appusers" className="dash-nav-item">
						<i className="fa fa-user-circle" /> App Users{" "}
					</NavLink>
				)}

				{!orgNotAllowed("") && viewPermission("carDetails") && (
					<NavLink activeClassName="active-bar" to="/cardetails" className="dash-nav-item">
						<i className="fas fa-car" /> Car Details{" "}
					</NavLink>
				)}

				{/* <NavLink activeClassName="active-bar" to='/agents' className='dash-nav-item'>
            <i className='fas fa-user' /> Agent{' '}
          </NavLink> */}

				<NavLink activeClassName="active-bar" to="/settings" id="#settings" className="dash-nav-item settings">
					<i className="fas fa-cog" /> Settings{" "}
				</NavLink>

				{/* <NavLink activeClassName="active-bar" to='/cardetails' className='dash-nav-item'>
            <i className='fas fa-chart-bar' /> Ride Offers{' '}
          </NavLink>

          <NavLink activeClassName="active-bar" to='/unique' className='dash-nav-item'>
            <i className='fas fa-cube' /> Ride Request{' '}
          </NavLink>

          <NavLink activeClassName="active-bar" to='/parkspace' className='dash-nav-item'>
            <i className='fas fa-file' /> Unique Users{' '}
          </NavLink>

          <NavLink activeClassName="active-bar" to='/parkspace' className='dash-nav-item'>
            <i className='fas fa-info' /> Parking Fees{' '}
          </NavLink> */}
			</nav>
		</div>
	);
};

export default SideNav;

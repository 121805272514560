import React, { Component } from "react";
import { toast } from "react-toastify";
import Status from "./Status";
import Loader from "./Loader";

import { viewPermission, exactViewPermission, orgAllowed, isHaggai, brandname, orgNotAllowed, isBroad } from "../../../actions/permission";
import { getAllTransfers, retryTransfer, getUserIdAndPhoneId } from "./../../../reducers/transfers";
import { postTransferFile } from "../../../actions/bulkTransferActions";
import { connect } from "react-redux";
import FilterView from "./FilterView";
import Pagination from "./Pagination";
import { Button, Container, Table, Row, Col, UncontrolledTooltip, Input } from "reactstrap";

import Modal from "react-modal";
import Spinner from "react-spinner-material";
import { notify } from "./utils/notifications";
Modal.setAppElement("#root");

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};

class Transfer extends Component {
	state = {
		submitAuthorization: false,
		token: "",
		modalIsOpen: false,
		selectedFile: null,
		loading: false,
		transfers: [],
		orgId: "",
		filterObj: {},
		paginationObj: {
			limit: 20,
			offset: 0,
		},
		pageHistory: {
			currentPage: 1,
			totalPages: 0,
		},
		initiatorID: "",
		initiatorName: "",
		userId: "",
		phoneId: "",
		orgId: "",
	};
	componentDidMount = async () => {
		this.setState({ loading: true });
		const { user } = window.localStorage;

		const { phone, org_id: orgId, firstname, lastname, id } = JSON.parse(user);
		const ids = await this.props.getUserIdAndPhoneId({ phone });
		console.log("phone", phone);
		console.log("ids", ids);
		const { phone_id: phoneId, user_id: userId } = ids;

		this.setState({
			phoneId,
			userId,
			orgId,
			initiatorID: id,
			initiatorName: `${firstname} ${lastname}`,
		});

		try {
			const transfers = await this.props.getAllTransfers({
				orgId,
				...this.state.filterObj,
				...this.state.paginationObj,
			});

			if (transfers.error) {
				notify(transfers.message, "error");
			} else {
				const { total_count: totalCount, offset, limit } = transfers.pagination;
				const totalPages = Math.ceil(totalCount / limit);
				const currentPage = offset / limit + 1;
				this.setState({
					transfers: transfers.data,
					pageHistory: { currentPage, totalPages },
				});
			}
		} catch (err) {
			console.log(err);
		}
		this.setState({ loading: false });
	};

	handleChangeFile = (event) => {
		this.setState({ selectedFile: event.target.files[0] });
	};

	closeModal = () => {
		if (this.state.submitAuthorization) {
			return;
		}
		this.setState({ modalIsOpen: false, token: "", status: "" });
	};

	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	};

	onFileUpload = () => {
		if (this.state.selectedFile) {
			// Create an object of formData
			const formData = new FormData();

			const { token, userId, phoneId, orgId, initiatorID, initiatorName } = this.state;

			formData.append("token", token);
			formData.append("userId", userId);
			formData.append("phoneId", phoneId);
			formData.append("orgId", orgId);
			formData.append("initiatorID", initiatorID);
			formData.append("initiatorName", initiatorName);

			// Update the formData object
			formData.append("myFile", this.state.selectedFile, this.state.selectedFile.name);
			console.log("true");

			this.setState({ loading: true }, () => {
				this.props.postTransferFile(formData, this.handleCallback);
			});
		}
	};

	handleCallback = async (res) => {
		/*
    if (res.error) {
      this.notify(res.message, 'error');
    } else {
      await this.getTransferList();
      this.notify(res.message, 'success');
      this.setState({ submitAuthorization: false });
      this.closeModal();
    }
    */

		if (res === "success") {
			await this.refreshList();
			//await this.getTransferList();
			//this.notify(res.message, 'success');
			this.setState({ submitAuthorization: false });
			this.closeModal();
			toast.success("File uploaded successful");
			this.setState(
				{
					loading: false,
				},
				() => {}
			);
		} else {
			notify(res, "error");
			this.setState({ loading: false });
		}
	};
	refreshList = async () => {
		this.setState({ loading: true });
		try {
			const transfers = await this.props.getAllTransfers({
				orgId: this.state.orgId,
				...this.state.filterObj,
				...this.state.paginationObj,
			});
			if (transfers.error) {
				notify(transfers.message, "error");
			} else {
				const { total_count: totalCount, offset, limit } = transfers.pagination;
				const totalPages = Math.ceil(totalCount / limit);
				const currentPage = offset / limit + 1;
				this.setState({
					transfers: transfers.data,
					pageHistory: { currentPage, totalPages },
				});
			}
		} catch (err) {
			console.log(err);
		}
		this.setState({ loading: false });
	};

	handleRetry = async (id) => {
		this.setState({ loading: true });
		const res = await this.props.retryTransfer({
			transferId: id,
			orgId: this.state.orgId,
		});
		if (res.error) {
			notify(res.message, "error");
		} else {
			notify(res.message, "success");
			this.refreshList();
		}
		this.setState({ loading: false });
	};

	getTransferList = async () => {
		try {
			const { orgId } = this.state;
			const transfers = await this.props.getAllTransfers({
				orgId,
			});
			this.setState({ transfers: transfers.data });
		} catch (err) {
			console.log(err);
		}
	};

	mapTransfers = () => {
		const { transfers } = this.state;
		let numbering = 1;
		const mappedTransfers = transfers.map((transfer) => {
			let displayRetries = false;
			let statusIcon;
			const {
				id,
				sender_acct_name: senderAccountName,
				sender_acct_no: senderAccountNumber,
				beneficiary_acct_no: beneficiaryAccountNumber,
				beneficiary_acct_name: beneficiaryAccountName,
				beneficiary_org_name: beneficiaryBankName,
				beneficiary_org_id: beneficiaryBankCode,
				amount_wth_fees: amount,
				order_id: orderId,
				remarks,
				initiator_name: initiatorName,
				authorizer_name: authorizerName,
				status,
				created_at: date,
				bank_message: bankMessage,
				status_code: statusCode,
			} = transfer;

			switch (status) {
				case "initiated":
					statusIcon = (
						<div className="d-flex  align-items-center">
							<Status color={"#ec9b3b"} />
							<span className="ml-2" style={{ color: "#ec9b3b" }}>
								Initiated
							</span>
						</div>
					);
					break;
				case "pending":
					displayRetries = true;
					statusIcon = (
						<div className="d-flex  align-items-center">
							<Status color={"grey"} />
							<span className="ml-2" style={{ color: "#grey" }}>
								Pending
							</span>
						</div>
					);
					break;
				case "approval_one":
					statusIcon = (
						<div className="d-flex  align-items-center">
							<Status color={"grey"} />
							<span className="ml-2" style={{ color: "#grey" }}>
								Approval (B)
							</span>
						</div>
					);
					break;
				case "failed":
					statusIcon = (
						<div className="d-flex align-items-center">
							<Status color={"red"} />
							<span className="ml-2" style={{ color: "red" }}>
								Failed
							</span>
						</div>
					);
					break;
				case "rejected":
					statusIcon = (
						<div className="d-flex align-items-center">
							<Status color={"#f0134d"} />
							<span className="ml-2" style={{ color: "#f0134d" }}>
								Rejected
							</span>
						</div>
					);
					break;
				case "successful":
					statusIcon = (
						<div className="d-flex  align-items-center">
							<Status color={"green"} />
							<span className="ml-2" style={{ color: "green" }}>
								Successful
							</span>
						</div>
					);
					break;
				default:
					statusIcon = <Status color={"black"} />;
			}

			return (
				<tr className="tr" key={orderId}>
					<th scope="row">{statusIcon}</th>
					{bankMessage ? (
						<td>
							<UncontrolledTooltip target={`bankMessage${numbering}`}>
								{bankMessage} {statusCode}
							</UncontrolledTooltip>
							<a
								href=""
								rel="noopener noreferrer"
								className=""
								id={`bankMessage${numbering++}`}
								style={{ color: statusCode == "00" || statusCode == "99" ? "green" : "#f0134d" }}>
								<i className="fas fa-info"></i>
							</a>
						</td>
					) : (
						<td></td>
					)}
					{status === "successful" ? (
						<td>
							<UncontrolledTooltip target={`downloadButton${numbering}`}>Download receipt</UncontrolledTooltip>
							<a
								href={`https://api.jekalo.com/api/receipts/transfers?order_id=${orderId}`}
								target="_blank"
								rel="noopener noreferrer"
								className=""
								id={`downloadButton${numbering++}`}>
								<i className="fas fa-cloud-download-alt"></i>
							</a>
						</td>
					) : (
						<td></td>
					)}
					<td>{orderId}</td>
					<td>{senderAccountNumber}</td>
					<td>{senderAccountName}</td>
					<td>{beneficiaryAccountNumber}</td>
					<td>{beneficiaryAccountName}</td>
					<td>{beneficiaryBankName}</td>
					<td>₦{amount}</td>
					<td>{initiatorName}</td>
					<td>{authorizerName}</td>
					<td>{remarks}</td>
					<td>{date}</td>
					<td className="d-flex justify-content-center">
						{displayRetries && (
							<div className="btn " onClick={() => this.handleRetry(id)}>
								<i className="fas fa-redo-alt"></i>
							</div>
						)}
						{(!(isBroad("") || orgNotAllowed("")) || !exactViewPermission("approvals")) && (
							<div
								className="btn"
								onClick={() => {
									this.props.history.push("/transactions/newtransfer", {
										senderAccountName,
										senderAccountNo: senderAccountNumber,
										beneficiaryAccountName,
										beneficiaryAccountNo: beneficiaryAccountNumber,
										beneficiaryOrgId: beneficiaryBankCode,
										remarks,
										amount,
									});
								}}>
								<i className="fas fa-paste"></i>
							</div>
						)}
					</td>
				</tr>
			);
		});
		return mappedTransfers;
	};

	onFilter = (payload) => {
		this.setState(
			{
				filterObj: payload,
				paginationObj: {
					limit: 20,
					offset: 0,
				},
			},
			this.refreshList
		);
	};
	onPageChange = (pageNumber) => {
		const { paginationObj } = this.state;
		const newPaginationObj = { ...paginationObj };
		newPaginationObj.offset = (pageNumber - 1) * paginationObj.limit;
		this.setState({ paginationObj: newPaginationObj }, this.refreshList);
	};
	onChangePage = (pageNo) => {
		const { paginationObj } = this.state;
		const newPaginationObj = { ...paginationObj };
		newPaginationObj.offset = (pageNo - 1) * paginationObj.limit;
		this.setState({ paginationObj: newPaginationObj }, this.refreshList);
	};

	render() {
		const { loading, pageHistory, submitAuthorization, token } = this.state;
		const tokenError = token.length !== 6;
		const mappedTransfers = this.mapTransfers();
		return (
			<Container>
				<Row>{loading && <Loader />}</Row>
				<Row>
					<Col className="d-flex justify-content-start align-items-center">
						<Button className="text-uppercase d-flex align-items-center mr-1" color="success" onClick={this.refreshList}>
							<i className="fas fa-redo mr-2" style={{ fontSize: 10 }}></i> <small className="font-weight-bold">Refresh</small>
						</Button>

						<FilterView onFilter={this.onFilter} />

						{(!(isBroad("") || orgNotAllowed("")) || !exactViewPermission("approvals")) && (
							<div className="flex-1">
								<label className="text-uppercase d-flex align-items-center float-right mr-2" color="success">
									&nbsp;
									<small className="font-weight-bold">Bulk - [Click to upload] </small>
									{this.state.selectedFile && (
										<small className="text-lowercase font-weight-normal text-info">&nbsp;{this.state.selectedFile.name}</small>
									)}
									<Input accept="image/csv" onChange={this.handleChangeFile} type="file" name="id_image" className="d-none" />
									&nbsp;
									<Button
										className="text-uppercase d-flex align-items-center mr-1"
										color="success"
										onClick={() => {
											if (this.state.selectedFile) {
												this.setState({ modalIsOpen: true });
											} else {
												notify("FIle not yet uploaded", "error");
											}
										}}>
										Upload!
									</Button>
								</label>
							</div>
						)}
						<div className="row">
							{loading && <Loader />}
							<Modal
								isOpen={this.state.modalIsOpen}
								onAfterOpen={this.afterOpenModal}
								onRequestClose={this.closeModal}
								shouldCloseOnOverlayClick={false}
								style={customStyles}
								contentLabel="Example Modal">
								<h2 className="d-none" ref={(subtitle) => (this.subtitle = subtitle)}>
									Please enter your token
								</h2>
								<div class="d-flex justify-content-end">
									<div onClick={this.closeModal} style={{ padding: 5, cursor: "pointer" }}>
										<i className="fas fa-close text-danger"></i>
									</div>
								</div>
								<div className="form-group">
									<h6>Please enter your token</h6>
									<input type="text" className="form-control" name="token" value={this.state.token} onChange={this.handleChange} />
									<button
										onClick={this.onFileUpload}
										className="btn btn-primary d-flex justify-content-center align-items-center w-100 mt-2"
										disabled={tokenError || submitAuthorization}>
										{!submitAuthorization && (
											<small className="font-weight-bold text-uppercase">{this.state.status === "rejected" ? "Reject" : "Initiate"}</small>
										)}
										{submitAuthorization && <Spinner size={16} spinnerColor={"#ffffff"} spinnerWidth={2} visible={true} />}
									</button>
								</div>
							</Modal>
						</div>
						{(!(isBroad("") || orgNotAllowed("")) || !exactViewPermission("approvals")) && (
							<div className="flex-1">
								<Button
									className="text-uppercase d-flex align-items-center float-right mr-2"
									color="success"
									onClick={() => this.props.history.push("/transactions/newtransfer")}>
									<small className="font-weight-bold">Make transfer</small>
								</Button>
							</div>
						)}
					</Col>
				</Row>
				<Row>
					<Col>
						<Table borderless responsive>
							<thead>
								<tr>
									<th colSpan="3"></th>
									<th colSpan="2" className="text-center text-success">
										<small className="font-weight-bold text-uppercase">Sender</small>
									</th>
									<th colSpan="3" className="text-center text-success">
										<small className="font-weight-bold text-uppercase">Beneficiary</small>
									</th>
									<th colSpan="5"></th>
								</tr>
								<tr className="text-uppercase">
									<th>
										<small className="font-weight-bold">Status</small>
									</th>
									<th></th>
									<th></th>
									<th>
										<small className="font-weight-bold">Order ID</small>
									</th>
									<th>
										<small className="font-weight-bold">Account number</small>
									</th>
									<th>
										<small className="font-weight-bold">Account name</small>
									</th>
									<th>
										<small className="font-weight-bold">Account number</small>
									</th>
									<th>
										<small className="font-weight-bold">Account name</small>
									</th>
									<th>
										<small className="font-weight-bold">Bank</small>
									</th>
									<th>
										<small className="font-weight-bold">Amount</small>
									</th>
									<th>
										<small className="font-weight-bold">Initiator</small>
									</th>
									<th>
										<small className="font-weight-bold">Authorizer</small>
									</th>
									<th>
										<small className="font-weight-bold">Remarks</small>
									</th>
									<th>
										<small className="font-weight-bold">Date</small>
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>{mappedTransfers}</tbody>
						</Table>
					</Col>
				</Row>
				<Row>
					<Col className="d-flex justify-content-end mr-2">
						{pageHistory.totalPages > 1 && <Pagination pageHistory={this.state.pageHistory} onPageChange={this.onPageChange} />}
					</Col>
				</Row>
			</Container>
		);
	}
}

const mapDispatchToProps = {
	getUserIdAndPhoneId,
	getAllTransfers,
	retryTransfer,
	postTransferFile,
};
/*
const xmapDispatchToProps = (dispatch) => {
  //return {
  getUserIdAndPhoneId,
  getAllTransfers,
  retryTransfer,
  postTransferFile
  //postTransferFile: (payload, cd) => dispatch(postTransferFile(payload, cd))
  //};
};
*/
export default connect(null, mapDispatchToProps)(Transfer);

import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
// import Dashboard from "./Dashboard";
import AppUsers from "./AppUsers";
import UsersInfo from "./UsersInfo";
import CarDetails from "./CarDetails";
import UniqueUsers from "./UniqueUsers";
import ParkSpace from "./ParkSpace";
import SideNav from "../SideNav/index";
import Header from "./Header/index";
import Profile from "./Settings/Profile";
import AgentsDetails from "./AgentsDetails";
import Transactions from "./Transactions";
import AutoRepayment from "./Transactions/AutoRepayment.js";
import Teller from "../MainNav/Transactions/teller";
import Transfer from "./Transactions/transfer";
import NewTransfer from "./Transactions/newTransfer";
import BillsPayment from "./Transactions/BillsPayment";
import PayBills from "./Transactions/PayBills";
import BatchManageTransfers from "./Transactions/BatchManageTransfers";
import BulkSms from "./bulk-sms/BulkSms";

import BalanceHistory from "./Transactions/balanceHistory";
import ChequeTransaction from "./Transactions/ChequeTransaction";
import BankStatement from "./Transactions/BankStatement.js";
import CashTransaction from "./Transactions/CashTransaction";
import Dashboard from "./Dashboard2";
import AccountOpening from "./Accounts/accountOpening";
import ViewAccount from "./Accounts/viewAccount";
import Trip from "./main/trip";
// import LoginPage from '../loginSingup/Login';
import { setAdminStatus } from "../../reducers/home";
import "../../assets/js/scripts.js";
import "../../assets/css/styles.css";
import BulkSmsHistory from "./bulk-sms/BulkSmsHistory";
import Salary from "./Loan/Salary";
import CustomerLoan from "./Loan/CustomerLoan";
import CustomerAccount from "./CustomerAccount";
import { EmailTemplate } from "../email-template";
import pendingPostings from "./Transactions/pendingPostings";
import Journal from "./Journal";
import { toast } from "react-toastify";

// Define inactivity timeout constants
const INACTIVITY_TIME_LIMIT = 5 * 60 * 1000; // change to 5 minutes 

const FINAL_MINUTE_INTERVAL = 10 * 1000; 
const FINAL_MINUTE_DURATION = 1 * 60 * 1000; 


export class MainNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: false,
      name: "Adelaja Ibrahim Abiola",
      finalMinuteTimer: null,
    };

    this.inactivityTimer = null; 
    this.finalMinuteInterval = null; 
  }

  componentDidMount() {
    this.resetInactivityTimer();
    window.addEventListener("mousemove", this.resetInactivityTimer);
    window.addEventListener("keydown", this.resetInactivityTimer);
    window.addEventListener("click", this.resetInactivityTimer);
  }

  componentWillUnmount() {
    // Cleanup event listeners and timers
    this.clearInactivityTimer();
    this.clearFinalMinuteTimer();
    window.removeEventListener("mousemove", this.resetInactivityTimer);
    window.removeEventListener("keydown", this.resetInactivityTimer);
    window.removeEventListener("click", this.resetInactivityTimer);
  }

  resetInactivityTimer = () => {
    this.clearInactivityTimer();
    this.clearFinalMinuteTimer();

    this.inactivityTimer = setTimeout(() => {
      this.startFinalMinuteCountdown();
    }, INACTIVITY_TIME_LIMIT - FINAL_MINUTE_DURATION);
  };

  clearInactivityTimer = () => {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
  };

  clearFinalMinuteTimer = () => {
    if (this.finalMinuteInterval) {
      clearInterval(this.finalMinuteInterval);
    }
  };

  startFinalMinuteCountdown = () => {
    let remainingTime = FINAL_MINUTE_DURATION;

    this.finalMinuteInterval = setInterval(() => {
      remainingTime -= FINAL_MINUTE_INTERVAL;

      if (remainingTime > 0) {
        toast.warning(
          `You will be logged out in ${
            remainingTime / 1000
          } seconds due to inactivity.`,
          { autoClose: 5000 }
        );
      } else {
        this.clearFinalMinuteTimer();
        this.handleLogout();
      }
    }, FINAL_MINUTE_INTERVAL);
  };

  handleLogout = () => {
    console.log("User logged out due to inactivity");
    this.setState({ login: false });

    sessionStorage.setItem("status", false);
    localStorage.removeItem("user");

    toast.error(
      "You are logged out due to inactivity, you will be redirected in 3 seconds",
      { autoClose: 3000 }
    );

    setTimeout(() => {
      window.location.replace("/");
    }, 3000);
  };

  render() {
    return (
      <Router>
          <div className="dash">
            <SideNav />
            <div className="dash-app">
              <Header />
              <Switch>
                {/* <Redirect from='/' to='/dashboard' /> */}
                <Route path="/main/trip" component={Trip} />
                <Route
                  path="/transactions/accountOpening"
                  component={AccountOpening}
                />
                <Route path="/account/customer" component={CustomerAccount} />
                <Route
                  path="/transactions/viewaccount"
                  component={ViewAccount}
                />
                <Route path="/dashboard" component={Dashboard} />
                {/* <Route path='/dashboard' component={Dashboard} /> */}
                <Route
                  path="/transactions/batch-manage"
                  component={BatchManageTransfers}
                />
                <Route
                  path="/transactions/balanceHistory"
                  component={BalanceHistory}
                />
                <Route path="/appusers" component={AppUsers} />
                <Route
                  path="/transactions/ndd-autorepayment"
                  component={AutoRepayment}
                />
                <Route
                  path="/transactions/autorepayment"
                  component={Transactions}
                />
                <Route path="/transactions/teller" component={Teller} />
                <Route path="/transactions/transfer" component={Transfer} />
                <Route
                  path="/transactions/newtransfer"
                  component={NewTransfer}
                />
                <Route
                  path="/transactions/bills-payment"
                  component={BillsPayment}
                />
                <Route
                  path="/transactions/cheque"
                  component={ChequeTransaction}
                />

                <Route
                  path="/transactions/statement"
                  component={BankStatement}
                />

                <Route path="/transactions/cash" component={CashTransaction} />
                <Route
                  path="/transactions/pending-postings"
                  component={pendingPostings}
                />
                <Route path="/transactions/pay-bills" component={PayBills} />
                <Route path="/usersInfo" component={UsersInfo} />
                <Route path="/journal" component={Journal} />
                <Route path="/cardetails" component={CarDetails} />
                <Route path="/unique" component={UniqueUsers} />
                <Route path="/parkspace" component={ParkSpace} />
                <Route path="/agents" component={AgentsDetails} />
                <Route path="/settings" component={Profile} />
                <Route path="/bulk-sms/new" component={BulkSms} />
                <Route path="/bulk-sms/history" component={BulkSmsHistory} />

                <Route path="/loan/salary" component={Salary} />
                <Route path="/loan/customerloan" component={CustomerLoan} />
                <Route path="/email-template" component={EmailTemplate} />

                <Route component={Dashboard} />
              </Switch>
            </div>
          </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  showAdminStatus: state.home.showAdminStatus,
});

const mapDispatchToProps = {
  setAdminStatus,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainNav)
);
